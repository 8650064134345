import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useStore } from 'react-redux';
import { storeMajor, getMajors, updateMajor } from './../../apis/services';
import { Modal } from 'react-bootstrap';
import { Checkbox } from "primereact/checkbox";
import { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { InputSwitch } from "primereact/inputswitch";
import { setShowDeleteDialog } from '../../redux/reducer';
import DeleteModalContent from '../../commons/DeleteModalContent';
import { getTimeStamp } from '../../helpers/helpers';
const Majors = () => {
    const { t } = useTranslation();
    const store = useStore();
    const [majors, setMajors] = useState([]);
    const [showAddMajorsModal, setShowAddMajorsModal] = useState(false);
    const [showEditMajorsModal, setShowEditMajorsModal] = useState({ show: false, item: '' });
    useEffect(() => {
        loadMajors();
    }, []);
    const loadMajors = async () => {
        try {
            const res = await getMajors();
            setMajors(res.data.majors);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="d-flex jcsb">
                    <div className='mt-2 mb-2'>
                        <h4>{t('Manage Majors')}</h4>
                    </div>
                    <div className='p-2'>
                        <Button className='p-btn'
                            onClick={() => {
                                setShowAddMajorsModal(true);
                            }}
                        > {t('Add Major')}</Button>
                    </div>
                </div>
                <Modal
                    show={showAddMajorsModal}
                >
                    <div className='p-4'>
                        <AddMajors
                            submit={() => {
                                setShowAddMajorsModal(false);
                                loadMajors();
                            }}
                            cancel={() => {
                                setShowAddMajorsModal(false);
                            }}
                        />
                    </div>
                </Modal>
                <Modal
                    show={showEditMajorsModal.show}
                >
                    <div className='p-4'>
                        <EditMajors
                            submit={() => {
                                setShowEditMajorsModal({ show: false, item: '' });
                                loadMajors();
                            }}
                            cancel={() => {
                                setShowEditMajorsModal({ show: false, item: '' });
                            }}
                            item={showEditMajorsModal.item}
                        />
                    </div>
                </Modal>
                {

                }
                <DeleteModalContent
                    reload={() => {
                        loadMajors();
                    }}
                />
                <div className="data-table mt-2">
                    <DataTable
                        value={majors}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    >
                       
                        <Column
                            field="name"
                            header={t('name')}
                            sortable
                        />
                      
                      <Column
                            field="code"
                            header={t('code')}
                            sortable
                        />
                      
                        <Column
                            header={t('status')}
                            sortField='status'
                            sortable
                            body={(row) => {
                                return (
                                    <InputSwitch checked={row.status === 1 ? true : false} />
                                )
                            }}
                        />
                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Button
                                            onClick={(e) => {
                                                setShowEditMajorsModal({ show: true, item: row });
                                            }}
                                            className='icon-btn mx-1' severity='primary' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                edit
                                            </span>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                store.dispatch(setShowDeleteDialog({ show: true, url: '/majors/delete/' + row.id }))
                                            }}
                                            className='icon-btn mx-1' severity='danger' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </Button>
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );

}

const AddMajors = (props) => {
    const { t } = useTranslation();
    const [majorTitle, setMajorTitle] = useState();
    const [status, setStatus] = useState(true);
    const [code, setCode] = useState();
    const handleAddMajors = async () => {
        try {
            let data = {
                majorTitle:majorTitle,
                status:status,
                code:code,
            }
            await storeMajor(data);
            props.submit();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <form action=""
            onSubmit={(e) => {
                e.preventDefault();
                handleAddMajors();
            }}
        >
            <div className="row">
                <div className="col-12">
                    <div className="d-flex jcsb align-items-center">
                        <h5 className='mt-2 mb-3 opacity'>{t('Add Major')}</h5>
                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                props.cancel();
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="majorTitle" className='required mb-1' >{t('Title')}</label>
                        <input type="text" id="majorTitle" className='form-control' required
                            value={majorTitle}
                            onChange={(e) => { setMajorTitle(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="code" className='required mb-1' >{t('Code')}</label>
                        <input type="text" id="majorTitle" className='form-control' required
                            value={code}
                            onChange={(e) => { setCode(e.target.value) }}
                        />
                    </div>
                </div>
                
               
                <div className="col-md-12 mt-2">
                    <div className="d-flex">
                        <div className="flex align-items-center">
                            <Checkbox inputId="status" name="status" checked={status} className=''
                                onChange={() => {
                                    setStatus(!status);
                                }}
                            />
                            <label htmlFor="status" className="ml-2 mx-2">{t('enable_for_use')}</label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <Button type='submit' className='p-btn'>{t('save')}</Button>
                </div>
            </div>
        </form>
    );
}
const EditMajors = (props) => {
    const { t } = useTranslation();
    const item = props.item;
    const [majorTitle, setMajorTitle] = useState(item.name);
    const [code, setCode] = useState(item.code);
    const [status, setStatus] = useState(item.status === 1 ? true : false);

    const handleEditMajors = async () => {
        try {
            let data = {
                id:item.id,
                majorTitle:majorTitle,
                status:status,
                code:code,
            }
            await updateMajor(data);
            props.submit();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <form action=""
            onSubmit={(e) => {
                e.preventDefault();
                handleEditMajors();
            }}
        >
            <div className="row">
                <div className="col-12">
                    <div className="d-flex jcsb align-items-center">
                        <h5 className='mt-2 mb-3 opacity'>{t('Edit Major')}</h5>
                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                props.cancel();
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="majorTitle" className='required mb-1' >{t('Title')}</label>
                        <input type="text" id="majorTitle" className='form-control' required
                            value={majorTitle}
                            onChange={(e) => { setMajorTitle(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="code" className='required mb-1' >{t('Code')}</label>
                        <input type="text" id="majorTitle" className='form-control' required
                            value={code}
                            onChange={(e) => { setCode(e.target.value) }}
                        />
                    </div>
                </div>
                
             
                <div className="col-md-12 mt-2">
                    <div className="d-flex">
                        <div className="flex align-items-center">
                            <Checkbox inputId="status" name="status" checked={status} className=''
                                onChange={() => {
                                    setStatus(!status);
                                }}
                            />
                            <label htmlFor="status" className="ml-2 mx-2">{t('enable_for_use')}</label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <Button type='submit' className='p-btn'>{t('update')}</Button>
                </div>
            </div>
        </form>
    );
}
export default Majors;