import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useStore } from 'react-redux';
import { getHomeWorkData, approveHomeWork } from './../../apis/services';
import { Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from "primereact/inputswitch";
import { Link, useLocation,useNavigate } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import { Galleria } from 'primereact/galleria';
const ViewAssignment = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const hw_id = location.state;
    const [hwData, setHwData] = useState();
    
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];
    useEffect(() => {
        loadHomeWorkData();
    }, []);
    const loadHomeWorkData = async () => {
        try {
            let params = {
                id: hw_id,
            };
            const resp = await getHomeWorkData(params);
            setHwData(resp.data.homework);
        } catch (error) {

        }
    }
    const handleApproveHW = async () => {
        try {
            let data = {
                comments: 'commemts',
                id: hwData.id,
                stud_id:hwData.stud_id,
            }
            const res = await approveHomeWork(data);
            // loadHomeWorkData();
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    }
    const thumbnailTemplate = (a) => {
        return (
            <img src={a.uri}
                style={{
                    width: 50,
                    height: 50
                }}
            />
        );
    }
    const itemTemplate = (a) => {
        return (
            <div className='p-3 text-center'>
                <img src={a.uri}
                    style={{
                        // width: 550,
                        height: 550,
                        textAlign: 'center',
                        alignSelf: 'center'

                    }}
                />
            </div>
        );

    }

    return (
        <div>
            <div className="p-3">
                {
                    hwData === undefined &&
                    <div className="row">
                        <div className="col-md-4">
                            <div className="p2">
                                <Skeleton height={300} ></Skeleton>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="p2">
                                <Skeleton height={300} ></Skeleton>
                            </div>
                        </div>
                    </div>
                }
                {
                    hwData !== undefined &&
                    <div className="row">
                        <div className="col-md-7">
                            <div className="p2 glass-card mb-3 p-2"
                                style={{
                                    backgroundColor: '#FFF',
                                }}
                            >
                                <Galleria value={JSON.parse(hwData.attachments)} responsiveOptions={responsiveOptions} numVisible={5}
                                    item={itemTemplate} thumbnail={thumbnailTemplate}
                                />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="p2 glass-card"
                                style={{
                                    backgroundColor: '#3F51B5',
                                    color: "#fff"
                                }}
                            >
                                <div className="d-flex jcsb p-3">
                                    <span>Created By</span>
                                    <span><strong>{hwData.alias_name}</strong></span>
                                </div>
                                <div className="d-flex jcsb p-3">
                                    <span>Subject</span>
                                    <span><strong>{hwData.hw_title}</strong></span>
                                </div>
                                <div className="d-flex jcsb p-3">
                                    <span>Type</span>
                                    <span><strong>{hwData.hw_type}</strong></span>
                                </div>
                                <div className="d-flex jcsb p-3">
                                    <span>Due Date</span>
                                    <span><strong>{hwData.due_date}</strong></span>
                                </div>
                                <div className="d-flex jcsb p-3">
                                    <span>Urgent</span>
                                    <span><strong>{hwData.urgent === 1 ? 'Yes' :'No'}</strong></span>
                                </div>
                                <div className="d-flex jcsb p-3">
                                    <span>Status</span>
                                    <span><strong>{hwData.approved === 0 ? "Under Review" :hwData.answered === 0 ? 'Quality Check Passed' :'Answered'}</strong></span>
                                </div>
                                <div className="d-flex jcsb p-3">
                                    <span>Created At</span>
                                    <span><strong>{hwData.created_at}</strong></span>
                                </div>
                                <div className="d-flex jcsb p-3">
                                    <span>Cource Name/Number</span>
                                    <span><strong>{hwData.cource_name_number}</strong></span>
                                </div>
                                <div className="d-flex jcsb p-3">
                                    <span>Course Instructor Name</span>
                                    <span><strong>{hwData.course_instructor_name}</strong></span>
                                </div>
                            </div>
                            <div className="p-3">
                                {
                                    hwData.approved === 0 &&
                                    <div>
                                        <Button label='Approve' className='mx-2 p-btn' 
                                            onClick={()=>{
                                                handleApproveHW();
                                            }}
                                        />
                                        <Button label='Reject' severity='danger' className='mx-2 round-btn' />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
export default ViewAssignment;