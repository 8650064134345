let baseUrl = process.env.REACT_APP_BASE_URL + 'api/v1';
const urls = {
    host: baseUrl,
    delete_record: baseUrl,
    login_url: baseUrl + '/user-login',
    get_user: baseUrl + '/logged-user',

    add_subject_category: baseUrl + '/add-subject-category',
    get_subject_categories: baseUrl + '/get-subject-categories',
    edit_subject_category: baseUrl + '/update-subject-category',

    add_subject: baseUrl + '/add-subject',
    get_subjects: baseUrl + '/get-subjects',
    edit_subject: baseUrl + '/update-subject',

    get_majors: baseUrl + '/get-majors',
    add_major: baseUrl + '/add-major',
    edit_major: baseUrl + '/update-major',

    get_colleges: baseUrl + '/get-colleges',
    add_college: baseUrl + '/add-college',
    edit_college: baseUrl + '/update-college',

    get_qa_assignments: baseUrl + '/qa-homeworks',
    get_home_work_data: baseUrl + '/home-work-data',
    approve_home_work: baseUrl + '/approve-home-work',

}


export default urls;