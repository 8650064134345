import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useStore } from 'react-redux';
import { getQAAssignments } from './../../apis/services';
import { Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from "primereact/inputswitch";
import { Link } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
const QAAssignments = () => {
    const { t } = useTranslation();
    const store = useStore();
    const [assignments, setAssignments] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    useEffect(() => {
        loadQAHomeWorks();
    }, []);
    const loadQAHomeWorks = async () => {
        try {
            let data = {};
            const res = await getQAAssignments(data);
            setAssignments(res.data.homeworks);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="d-flex jcsb">
                    <div className='mt-2 mb-2'>
                        <h4>{t('Manage Assignments')}</h4>
                    </div>
                    {/* <div className='p-2'>
                        <Button className='p-btn' label='ss' />
                    </div> */}
                </div>
                <div className="data-table mt-2">
                    <DataTable
                        value={assignments}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        filters={filters}
                        header={
                            <div className='row'>
                                <div className="col-md-6">
                                    <input className='form-control'
                                        placeholder='Search..'
                                        onChange={(e) => {
                                            let _filters = { ...filters };
                                            _filters['global'].value = e.target.value;
                                            setFilters(_filters);
                                        }}
                                    />
                                </div>

                            </div>
                        }
                    >

                        <Column
                            field="hw_title"
                            header={t('Subject')}
                            sortable
                        />
                        <Column
                            field="hw_type"
                            header={t('Type')}
                            sortable
                        />
                        <Column
                            header={t('Urgent')}
                            sortField='urgent'
                            sortable
                            body={(row) => {
                                return (
                                    <InputSwitch checked={row.urgent === 1 ? true : false} />
                                )
                            }}
                        />

                        <Column
                            header={"Status"}
                            body={(r) => <span><strong>{r.approved === 0 ? "Under Review" : r.answered === 0 ? 'Quality Check Passed' : 'Answered'}</strong></span>}
                        />
                        <Column
                            field="due_date"
                            header={t('Due Date')}
                            sortable
                        />

                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Link
                                            to="/assignments/view"
                                            state={row.id}
                                            className='link-icon-btn mx-1' severity='info' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                visibility
                                            </span>
                                        </Link>
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default QAAssignments;