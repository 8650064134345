import urls from "./urls";
import axios from "./axios";


export const checkLogin = async (token) => {
    try {
        const response = await axios({
            method: 'POST',
            url: urls.api_server + urls.check_token,
            params: {
                token: token,
            }
        });
        return response;
    } catch (error) {
        return false;
    }

}
export const startLogin = async (user) => {
    try {
        let response = await axios({
            method: 'POST',
            data: user,
            url: urls.login_url,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getUserData = async (token) => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_user,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const deleteRecord = async (url) => {
    try {
        let response = await axios({
            method: 'DELETE',
            url: urls.delete_record + url,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const storeSubject = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.add_subject,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const updateSubject = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.edit_subject,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getSubjects = async (token) => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_subjects,
        });
        return response;
    } catch (error) {
        return error;
    }
}




export const getSubjectCategories = async (params) => {
    try {
        let response = await axios({
            method: 'GET',
            params: params,
            url: urls.get_subject_categories,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const storeSubjectCategory = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.add_subject_category,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateSubjectCategory = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_subject_category,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getMajors = async (params) => {
    try {
        let response = await axios({
            method: 'GET',
            params: params,
            url: urls.get_majors,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const storeMajor = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.add_major,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateMajor = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_major,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getColleges = async (params) => {
    try {
        let response = await axios({
            method: 'GET',
            params: params,
            url: urls.get_colleges,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const storeCollege = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.add_college,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateCollege = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_college,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getQAAssignments = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.get_qa_assignments,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getHomeWorkData = async (params) => {
    let response = await axios({
        method: 'GET',
        url: urls.get_home_work_data,
        params: params,
    });
    return response;
}
export const approveHomeWork = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.approve_home_work,
        data: data,
    });
    return response;
}


